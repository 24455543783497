.image-wrapper {
  height: 7.5rem;
  width: 100%;
  display: flex;
  
  -webkit-box-shadow: 0px 5px 5px rgb(71, 71, 71,0.25);
  -moz-box-shadow: 0px 5px 5px rgb(71, 71, 71,0.25);
  box-shadow: 0px 5px 5px rgb(71, 71, 71,0.25);
}
.image-wrapper img {
  height: 100%; 
}
.wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  background: #000;
  transition: all 0.45s ease-in-out;
  font-family: "Poppins", sans-serif;
  
}

#active:checked ~ .wrapper {
  /*left: 0;*/

  right: 0;
}
.menu-btn {
  position: absolute;
  z-index: 10001;
  right: 20px;
  /*left: 20px; */
  top: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  /*color: #fff;*/
  /*background: linear-gradient(90deg, #f92c78, #4114a1);*/
  /* background: linear-gradient(375deg, #1cc7d0, #2ede98); */
  /* background: linear-gradient(-45deg, #e3eefe 0%, #efddfb 100%); */
  transition: all 0.3s ease-in-out;
}
.menu-btn span,
.menu-btn:before,
.menu-btn:after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 30%;
  width: 40%;
  border-bottom: 2px solid #000;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.menu-btn:before {
  transform: translateY(-8px);
}
.menu-btn:after {
  transform: translateY(8px);
}

.close-btn {
  z-index: 10001;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: background 0.6s;
}

/* closing animation */
#active:checked + .menu-btn span {
  transform: scaleX(0);
}
#active:checked + .menu-btn:before {
  transform: rotate(45deg);
  border-color: #fff;
}
#active:checked + .menu-btn:after {
  transform: rotate(-45deg);
  border-color: #fff;
}
.wrapper ul {
  position: relative;
  top: 75%;
  left: 50%;
  height: 90%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
  align-items: center;
}
.wrapper ul li {
  height: 10%;
  margin: 15px 0;
}
.wrapper ul li a {
  text-decoration: none;
  font-size: 30px;
  font-weight: 500;
  padding: 5px 30px;
  color: #fff;
  border-radius: 50px;
  position: absolute;
  line-height: 50px;
  margin: 5px 30px;
  opacity: 0;
  transition: all 0.3s ease;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.wrapper ul li a:after {
  position: absolute;
  content: "";
  background: #fff;
  /*background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);*/
  /*background: linear-gradient(375deg, #1cc7d0, #2ede98);*/
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50px;
  transform: scaleY(0);
  z-index: -1;
  transition: transform 0.3s ease;
}
.wrapper ul li a:hover:after {
  transform: scaleY(1);
}
.wrapper ul li a:hover {
  color: #f58840;
}
input[type="checkbox"] {
  display: none;
}

#active:checked ~ .wrapper ul li a {
  opacity: 1;
}
.wrapper ul li a {
  transition: opacity 1.2s, transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateX(100px);
}
#active:checked ~ .wrapper ul li a {
  transform: none;
  transition-timing-function: ease, cubic-bezier(0.1, 1.3, 0.3, 1); /* easeOutBackを緩めた感じ */
  transition-delay: 0.6s;
  transform: translateX(-100px);
}
