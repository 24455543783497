*,
*:before,
*:after {
  box-sizing: border-box;
}

.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 100px 10px 20px 100px;
  color: #fff;
  background-color: #f58840;
  border-top: 1px solid #e5e5e5;
}

.footer > * {
  flex: 1 100%;
}

.footer__addr {
  margin-right: 1.25em;
  margin-bottom: 2em;
}

.footer__logo {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.footer__addr h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}

.nav__title {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 1.25em;
}

.footer address {
  font-style: normal;
  color: #999;
}

.footer__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: rgb(33, 33, 33, 0.07);
  border-radius: 100px;
  color: #fff;
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1rem;
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
  margin-bottom: 10px;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
  flex-flow: row wrap;
}

.footer__nav > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul a {
  color: #fff;
  background-color: #252525;
  padding: 10px;
  transition: all;
  transition-duration: 0.2s;
}
.nav__ul a:hover {
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.nav__ul--extra {
  /* column-count: 2;
  column-gap: 1.25em; */
}

.legal {
  display: flex;
  color: #fff;
  margin-top: 10%;
  /* border: #252525 solid 1px; */
  justify-content: space-between;
  margin-right: 5%;
  flex-wrap: wrap;
}

.legal a {
  font-size: 1.5em;
  color: #fff;
  margin-left: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
  padding-top: 5px;

}
.legal a:hover {
  color: #fff;
  cursor: pointer;

}
.heart {
  color: #fff;
}

@media (max-width: 600px) {
  .legal .legal__links {
    margin-left: auto;
  }
  .footer {
    margin-top: 110px;
    padding: 10%;
    text-align: center;
  }
  .footer__addr {
    border-bottom: #fff solid 1px;
  }
  .nav__item {
    padding-bottom: 20px;
    border-bottom: #fff solid 1px;
  }
  .nav__item--extra {
    border-bottom: none;
  }
  .legal a{
    margin-left: 75px;
  }
}

@media (min-width: 992px) {
  .footer__nav > * {
    flex: 1;
  }
  .nav__item--extra {
    flex-grow: 2;
  }

  .footer__addr {
    flex: 1 0px;
  }

  .footer__nav {
    flex: 2 0px;
  }
  
}
.icon {
  margin-right: 1%;
}
