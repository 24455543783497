.homepage-container {
  display: flex;
  flex-direction: column;
}
.categories {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  margin-top: 5%;
  margin-left: 10%;
}
.navbar-heading {
  color: #f58840 !important;
  font-family: "Poppins", sans-serif;
}
.active-category {
  color: #f58840 !important;
}

.products {
  display: flex;
  flex-wrap: wrap;
}
.category {
  border: transparent 1px solid;
}
.category:hover {
  border: #f58840 1px solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
@media (max-width: 992px) {
  .column {
    padding-left: 5%;
    padding-right: 5%;
  }
  .homepage-container {
    flex-direction: column;
  }
  .categories {
    display: flex;
  }
}
